<template>
  <div id="app" class=" main-wrapper" :id="appWrapperId">

    <vue-progress-bar></vue-progress-bar><!--    page-loading bar-->

    <router-view :key="$root.reloadIndex" v-slot="{ Component }">
      <transition name="zoom-fade" mode="out-in" >
        <component  :is="Component" ></component>
      </transition>
    </router-view>

    <div
        class="sidenav-overlay"
        :class="{show: !isVerticalMenuCollapsed || isSearchWindowOpened}"
        @click="overlayClicked()"
    />

  </div>
</template>

<script>

export default {

  // components:{},

  data: function(){
    return {

    }
  },
  watch:{

    '$route': {
      handler: function(currentRoute) {
        this.$store.commit('app/UPDATE_CURRENT_ROUTE', currentRoute);
      },
      deep: true,
      immediate: true
    },

  },

  methods:{

    resizeHandler(){
      this.$store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
    },

    overlayClicked(){

      if(!this.isVerticalMenuCollapsed){
        this.$store.commit('verticalMenu/TOGGLE_VERTICAL_MENU_COLLAPSED', true);
      } else if(this.isSearchWindowOpened){
        this.$store.commit('app/TOGGLE_IS_SEARCH_WINDOW_OPENED', false);
      }

    }
  },

  created(){
    window.addEventListener('resize', this.resizeHandler);
  },

  unmounted(){
    window.removeEventListener('resize', this.resizeHandler)
  },

  beforeCreate() {
    this.$store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
    this.$store.commit('theme/FILL_THEME_COLORS_AND_BREAKPOINTS', '');

    this.$store.commit('app/SET_ACTIVE_APP_INTERFACE', window.getAppInterface());

  },
}
</script>