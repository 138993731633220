import {assignLoggedInRoutes} from "@resources/js/routes/loggedUser-routes";
// console.log('itms admin',assignLoggedInRoutes('admin'));

export default {
    path: '/admin',
    component: () => import('@views/layouts/adminLayout'),
    // name: 'admin',
    meta: {
        breadcrumb: ''
    },
    children: [
        {
            path: '',
            component: () => import('@views/admin/DashboardAdmin'),
            name: 'admin.dashboard',
            meta: {
                breadcrumb: '',
                title: 'app.dashboard'
            }
        },

        {
            path: 'blog-categories',
            component: () => import('@views/admin/blog-categories/list'),
            name: 'admin.blog-categories',
            props: true,
            // meta: {
            //     breadcrumb: 'app.services',
            // },
        },
        {
            path: 'blog-articles',
            component: () => import('@views/admin/blog-articles/list'),
            name: 'admin.blog-articles',
            props: true,
            // meta: {
            //     breadcrumb: 'app.services',
            // },
        },

        // {
        //     path: 'information',
        //     component: () => import('@views/admin/PagePatientInformation'),
        //     name: 'patient.information',
        //     meta: {
        //         breadcrumb: 'app.information',
        //         title:'app.information'
        //     }
        // },
        {
            path: 'product-categories',
            component: () => import('@views/admin/product-categories/list.vue'),
            name: 'admin.product_categories.list',
            // props:true,
            meta: {
                breadcrumb: 'app.product_categories',
                title: 'app.product_categories'
            }
        },

        {
            path: 'faq-topics',
            component: () => import('@views/admin/faq-topics/list.vue'),
            name: 'admin.faq.list',
            // props:true,
            meta: {
                breadcrumb: 'app.faq_topics',
                title: 'app.faq_topics'
            }
        },

        {
            path: 'faqs',
            component: () => import('@views/admin/faqs/list.vue'),
            name: 'admin.faqs.list',
            // props:true,
            meta: {
                breadcrumb: 'app.faqs',
                title: 'app.faqs'
            }
        },

        {
            path: 'users',
            component: () => import('@views/admin/users/template.vue'),
            name: 'admin.users',
            props: true,
            meta: {
                breadcrumb: 'app.users',
            },

            children: [
                {
                    path: ':user_type',
                    component: () => import('@views/admin/users/list.vue'),
                    name: 'admin.users_type.list',
                    props: true,
                    meta: {

                        breadcrumb: '',
                        title: 'app.users'
                    }
                },
                //
                // {
                //     path: ':id/edit',
                //     component: () => import('@views/admin/users/addEdit.vue'),
                //     name: 'admin.users.edit',
                //     props: true,
                //     meta: {
                //         breadcrumb: 'app.editing',
                //         title: 'app.editing',
                //     }
                // },
                // {
                //     path: 'create',
                //     component: () => import('@views/admin/users/addEdit.vue'),
                //     name: 'admin.users.create',
                //     meta: {
                //         breadcrumb: 'app.creating',
                //         title: 'app.creating',
                //     }
                // }
            ]
        },
        {
            path: 'products',
            component: () => import('@views/admin/products/template.vue'),
            // name: 'admin.products',
            props: true,
            meta: {
                breadcrumb: 'app.services',
            },

            children: [
                {
                    path: '',
                    component: () => import('@views/admin/products/list'),
                    name: 'admin.products.list',
                    meta: {
                        breadcrumb: 'app.services',
                        title: 'app.services'
                    }
                },


                // {
                //     path: ':id/edit',
                //     component: () => import('@views/admin/users/addEdit.vue'),
                //     name: 'admin.users.edit',
                //     props: true,
                //     meta: {
                //         breadcrumb: 'app.editing',
                //         title: 'app.editing',
                //     }
                // },
                // {
                //     path: 'create',
                //     component: () => import('@views/admin/users/addEdit.vue'),
                //     name: 'admin.users.create',
                //     meta: {
                //         breadcrumb: 'app.creating',
                //         title: 'app.creating',
                //     }
                // }
            ]
        },
        {

            path: 'product-attributes',
            component: () => import('@views/admin/product-attributes/list'),
            name: 'admin.product_attributes.list',
            meta: {
                breadcrumb: 'app.service_attributes',
                title: 'app.service_attributes'
            }

        },
        {

            path: 'product-changeable-params',
            component: () => import('@views/admin/product-changeable-params/list'),
            name: 'admin.product_changeable_params.list',
            meta: {
                breadcrumb: 'app.service_changeable_params',
                title: 'app.service_changeable_params'
            }

        },

        {

            path: 'product-changeable-param-options',
            component: () => import('@views/admin/product-changeable-param-options/list'),
            name: 'admin.product_changeable_param_options.list',
            meta: {
                breadcrumb: 'app.service_changeable_param_options',
                title: 'app.service_changeable_param_options'
            }

        },
        {

            path: 'product-related-items',
            component: () => import('@views/admin/product-related-items/list'),
            name: 'admin.product_related_items.list',
            meta: {
                breadcrumb: 'app.service_related_items',
                title: 'app.service_related_items'
            }

        },
        {

            path: 'delivery-methods',
            component: () => import('@views/admin/delivery-methods/list'),
            name: 'admin.delivery_methods.list',
            meta: {
                breadcrumb: 'app.delivery_methods',
                title: 'app.delivery_methods'
            }

        },

        {
            path: 'orders/:order_type?',
            component: () => import('@views/admin/orders/list'),
            name: 'admin.orders.list',
            props: true,
            meta: {
                breadcrumb: 'app.orders',
                title: 'app.orders',
                permissions: ['orders:view']
            }
        },

        {

            path: 'units',
            component: () => import('@views/admin/units/list'),
            name: 'admin.units.list',
            meta: {
                breadcrumb: 'app.units',
                title: 'app.units'
            }

        },
        {
            path: 'product-reviews',
            component: () => import('@views/admin/product-reviews/list'),
            name: 'admin.product_reviews.list',
            meta: {
                breadcrumb: 'app.product_reviews',
                title: 'app.product_reviews',
            }
        },
        {
            path: 'roles',
            component: () => import('@views/admin/roles/list'),
            name: 'admin.roles.list',
            meta: {
                breadcrumb: 'app.roles',
                title: 'app.roles',
            }
        },
        {
            path: 'coupons',
            component: () => import('@views/admin/coupons/list'),
            name: 'admin.coupons.list',
            meta: {
                breadcrumb: 'app.coupons',
                title: 'app.coupons',
            },
        },
        {
            path: 'pages',
            component: () => import('@views/admin/pages/list'),
            name: 'admin.pages.list',
            meta: {
                breadcrumb: 'app.pages',
                title: 'app.pages',
            },
        },
        {
            path: 'app-translations',
            component: () => import('@views/admin/translations/appTranslations'),
            name: 'admin.translations.app_translations',
            meta: {
                breadcrumb: 'app.app_translations',
                title: 'app.app_translations',
            }
        },

        {
            path: 'client-translations',
            component: () => import('@views/admin/translations/clientTranslations'),
            name: 'admin.translations.client_translations',
            meta: {
                breadcrumb: 'app.client_translations',
                title: 'app.client_translations',
            }
        },

        {
            path: 'doctor-visit-types',
            component: () => import('@views/admin/doctor-types/list'),
            name: 'admin.doctor_types.list',
            meta: {
                breadcrumb: 'app.doctor_types',
                title: 'app.doctor_types',
            }
        },

     {
            path: 'patient-prescriptions',
            component: () => import('@views/logged/patient-prescriptions/list'),
            name: 'admin.patient_prescriptions.list',
            meta: {
                breadcrumb: 'app.patient_prescriptions',
                title: 'app.patient_prescriptions',
            }
        },

        {
            path: 'slides',
            component: () => import('@views/admin/slides/list'),
            name: 'admin.slides.list',
            meta: {
                breadcrumb: 'app.slides',
                title: 'app.slides',
            }
        },
        {
            path: 'common-site-settings',
            component: () => import('@views/admin/settings/siteSettings'),
            name: 'admin.settings.site_settings',
            meta: {
                breadcrumb: 'app.site_settings',
                title: 'app.site_settings',
                page_id: 'settings-page'
            }
        },
        {
            path: 'delivery-sender-address-settings',
            component: () => import('@views/admin/settings/deliverySenderAddressSettings'),
            name: 'admin.settings.delivery_sender_address',
            meta: {
                breadcrumb: 'app.delivery_sender_address',
                title: 'app.delivery_sender_address',
                page_id: 'settings-page'
            }
        },
        {
            path: 'smtp-settings',
            component: () => import('@views/admin/settings/smtpSettings'),
            name: 'admin.settings.smtp',
            meta: {
                breadcrumb: 'app.smtp',
                title: 'app.smtp',
                page_id: 'settings-page'
            }
        },


        ...(assignLoggedInRoutes('admin')),


    ]
}